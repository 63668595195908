<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

const checkoutRoutes = [
    'checkout-step-1-cart',
    'checkout-step-2-booking',
] as RouteLocationRaw[];

const steps = computed(() => {
    return [
        ...useFlowStore().flow?.steps.map(step => step.routeName) ?? [],
        ...checkoutRoutes,
    ];
});

const currentRoute = computed(() => getRouteName(useRoute()));
const totalDots = computed(() => steps.value.length);
const currentDot = computed(() => steps.value.indexOf(currentRoute.value) + 1);
</script>

<template>
    <ul class="flex space-x-1">
        <li
            v-for="dot in totalDots"
            :key="dot"
            class="size-[5px] rounded-full bg-brand-b transition-[width] duration-700"
            :class="dot === currentDot ? ' w-[30px]' : ''"
        />
    </ul>
</template>
