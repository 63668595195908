<script setup  lang="ts">
const { locale, locales } = useNuxtApp().$i18n;
const switchLocalePath = useSwitchLocalePath();

const availableLocales = computed(() => {
    return locales.value;
});
</script>

<template>
    <div class="flex items-center space-x-1">
        <NuxtLink v-for="l in availableLocales" :key="l.code" :to="switchLocalePath(l.code)" :class="l.code === locale ? 'font-bold' : ''">
            {{ l.code }}
        </NuxtLink>
    </div>
</template>
