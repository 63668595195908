<script setup lang="ts">
const { isLoading } = useLoading();
</script>

<template>
    <Transition name="loading-overlay">
        <div
            v-if="isLoading"
            class="
                fixed
                inset-0
                z-50
                bg-background-1/90
            "
        >
            <LoadingSpinner data-spinner class="absolute left-1/2 top-1/2 !size-14 -translate-x-1/2 -translate-y-1/2" />
        </div>
    </Transition>
</template>

<style scoped>
    .loading-overlay-enter-active,
    .loading-overlay-leave-active {
        transition: opacity 0.3s 0.2s;
    }
    .loading-overlay-enter-from {
        opacity: 0;
    }
    .loading-overlay-leave-to {
        opacity: 0;
    }

    .loading-overlay-enter-active [data-spinner],
    .loading-overlay-leave-active [data-spinner] {
        transition: opacity 0.3s 0.5s;
    }
    .loading-overlay-enter-from [data-spinner] {
        opacity: 0;
    }
    .loading-overlay-leave-to [data-spinner] {
        opacity: 0;
    }
</style>
