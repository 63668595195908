<script setup lang="ts">
import useFooterState from './useFooterState';

const {
    isEnabled,
    isPriceVisible,
    backwardCallback,
    backwardLabel,
    canGoBack,
    layout,
} = useFooterState();

const classes = computed(() => {
    const basicClasses = [
        'fixed',
        'z-10',
        'bottom-0',
        'md:bottom-4',
        'flex',
        'w-full',
        'h-[4.5rem]',
        'items-center',
        'justify-between',
        'md:transition-all',
        'md:duration-500',
    ];

    const whiteBarClasses = [
        'bg-white',
        'px-container-gap',
        'py-3',
        'border-t',
        'border-shade-1',
        'md:border-t-0',
        'md:rounded',
        'md:px-4',
        'md:shadow-lg',
    ];

    const floatingClasses = [
        'max-md:pr-container-gap',
        'max-md:pb-container-gap',
    ];

    const asidePosClasses = [
        'md:w-[calc(var(--dvw)_-_(var(--w-container-gap)_*_2)_-_var(--w-aside)_-_var(--w-col-gap))]',
        'md:max-w-[calc(var(--dvw)_-_(var(--w-container-gap)_*_2)_-_var(--w-aside)_-_var(--w-col-gap))]',
        'md:translate-x-[calc(var(--w-container-gap)_+_var(--w-aside)_+_var(--w-col-gap))]',
    ];

    switch (layout.value) {
        case 'narrow':
            return [
                ...basicClasses,
                ...whiteBarClasses,
                'md:max-w-[400px]',
                'md:translate-x-[calc(50vw_-_200px)]',
            ];
        case 'floating':
            return [
                ...basicClasses,
                ...asidePosClasses,
                ...floatingClasses,
            ];
        case 'default':
        default:
            return [
                ...basicClasses,
                ...asidePosClasses,
                ...whiteBarClasses,
            ];
    }
});
</script>

<template>
    <footer v-if="isEnabled" :class="classes">
        <div class="h-full">
            <Transition name="fade">
                <TheFooterPrice v-if="isPriceVisible" />
            </Transition>
        </div>

        <div class="flex min-[400px]:space-x-4">
            <button
                v-if="!!backwardCallback"
                :disabled="!canGoBack"
                class="justify-self-end px-4 transition-colors hover:text-shade-6 disabled:text-shade-4"
                @click="backwardCallback"
            >
                <span class="typo-button max-sm:hidden">
                    {{ backwardLabel.length ? backwardLabel : $t('core.buttons.back') }}
                </span>
                <Icon name="tot:backward" class="size-4 sm:hidden" mode="svg" :aria-label="$t('core.buttons.back')" />
            </button>

            <TheFooterForwardButton />
        </div>
    </footer>
</template>

<style scoped>
</style>
