<script setup lang="ts">
const { quote } = storeToRefs(useShopStore());
</script>

<template>
    <div v-if="quote" class="flex h-full items-center  border-shade-2 pr-4 typo-heading-md max-md:border-r">
        <span>
            {{ formatPrice(quote.price) }}
        </span>
    </div>
</template>
