<script setup  lang="ts">
const runtimeConfig = useRuntimeConfig();

function ciao() {
    if (runtimeConfig.public.enableLandingpage) {
        navigateTo('/');
    }
    else {
        navigateTo(runtimeConfig.public.urlJungfrau, { external: true });
    }
};
</script>

<template>
    <header class="px-container-gap pt-5 md:pt-7">
        <div class="flex items-center justify-between">
            <button @click="ciao">
                <Logo />
            </button>

            <TheProgressDots />
        </div>
    </header>
</template>
